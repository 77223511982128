export const Attorneys = {
    attorney:[
        {
            name:"Carlton Fields",
            website:"https://www.carltonfields.com/",
            phone:"(305) 539-7433"
        },
        {
            name:"Cozen O'Connor",
            website:"https://www.cozen.com/",
            phone:"(305) 704-5952"
        },
        {
            name:"DLA Piper",
            website:"https://www.dlapiper.com/en/us/",
            phone:"(404) 736-7804"
        },
        {
            name:"Foley & Lardner LLP",
            website:"https://www.foley.com/en",
            phone:"(305) 482-8400"
        },
        {
            name:"Patricia Elizee",
            website:"https://www.elizeelawfirm.com/",
            phone:"(305) 371-8846"
        },

    ]
}

export default Attorneys;
