import React, { useState } from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import Attorney from "../data/attorneys"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faPhoneVolume, faGlobe } from "@fortawesome/free-solid-svg-icons"
import "./immigration-attorneys.css"
import {Link} from "gatsby"

const ImmigrationAttorney = () => {
  const [attorneys, setAttorneys] = useState(Attorney.attorney)
  return (
    <Layout>
      <SEO title="Immigration Attorneys" />
      <div className="container primary-bg">
      <div>
          <Link className="linkHeader" to="/international-business-and-non-resident">International Business and Non-Resident Resources</Link>
          </div>
          <div>
        <label className="additonalFundingTitle">Immigration Attorneys</label>
        </div>
        {attorneys.map((attorney, index) => {
          return (
            <div>
              <hr />
              <label className="contactHeader">{attorney.name}</label>
              <div>
                <FontAwesomeIcon
                  className="iconStyle"
                  size="lg"
                  icon={faGlobe}
                />
                <a
                  className="contactText"
                  href={attorney.website}
                  target="_blank"
                >
                  {attorney.website}
                </a>
              </div>
              <div>
                <FontAwesomeIcon
                  size="lg"
                  className="iconStyle phoneIcon"
                  icon={faPhoneVolume}
                />
                <label className="contactText">{attorney.phone}</label>
              </div>
            </div>
          )
        })}
      </div>
    </Layout>
  )
}

export default ImmigrationAttorney
